export const theme = {
    name: 'job-speed',
    customRoutes: [{
        path: 'login',
        loadChildren: () => import('../app/pages/custom/job-speed/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'login/event-key/:event-key',
        loadChildren: () => import('../app/pages/custom/job-speed/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'login/access-key/:event-key',
        loadChildren: () => import('../app/pages/custom/job-speed/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'register',
        loadChildren: () => import('../app/pages/custom/job-speed/registration/registration.module').then(m => m.RegistrationPageModule),
    },
    {
        path: 'register/event-key/:event-key',
        loadChildren: () => import('../app/pages/custom/job-speed/registration/registration.module').then(m => m.RegistrationPageModule),
    },
    {
        path: 'register/access-key/:event-key',
        loadChildren: () => import('../app/pages/custom/job-speed/registration/registration.module').then(m => m.RegistrationPageModule),
    },
    {
        path: 'lost-password',
        loadChildren: () => import('../app/pages/custom/job-speed/lost-password/lost-password.module').then(m => m.LostPasswordPageModule)
    },
    {
        path: 'set-password',
        loadChildren: () => import('../app/pages/custom/job-speed/set-password/set-password.module').then(m => m.SetPasswordPageModule)
    },
    {
        path: 'toc',
        loadChildren: () => import('../app/pages/custom/job-speed/toc/toc.module').then(m => m.TocPageModule)
    }]
}
